import React from 'react'

import BaseLayout from '../templates/base'
import Head from '../components/head'
import SocialCard from '../components/social-card'
import PageHeader from '../components/page-header'

import config from '../../config/site-config'
import formatHtmlTitle from '../utils/formatHtmlTitle'

const AboutPage = ({ data }) => (
	<BaseLayout>
		<Head title={formatHtmlTitle(`About`)} />
		<SocialCard title={formatHtmlTitle(`About`)} />
		<PageHeader title={<span>Hi, I’m Harshil! <span className="about-wave">👋🏽</span></span>} />
		<div className="about-page">
			<p>I’m an independent iOS engineer living in Mumbai, India.</p>
			
			<p>I make <a href="https://sneakpeak.app" title="Peak">Peak</a>, an iPhone, iPad, & Apple Watch app that lets you build your own fitness dashboard, and <a href="/pause" title="Pause">Pause</a>, a Mac app that reminds you to rest.</p>
			
			<p>I’m currently looking for contract work. If you’d like to speak, you can <a href={config.social.email.url} title={`Send an email to ${config.social.email.username}`}>send me an email</a>.</p>
			
			<p>Previously, I have worked as an iOS engineer at Riff and Stopwatch.</p>

			<h3>Elsewhere on the Internet</h3>
			<ul>
				<li>
					<a href={config.social.bluesky.url} title={`Follow ${config.social.bluesky.username} on Bluesky`} target="_blank">
						Bluesky
					</a>
				</li>
				<li>
					<a href={config.social.mastodon.url} title={`Follow ${config.social.mastodon.username} on Mastodon`} target="_blank">
						Mastodon
					</a>
				</li>
				<li>
					<a href={config.social.twitter.url} title={`Follow ${config.social.twitter.username} on Twitter`} target="_blank">
						Twitter
					</a>
				</li>
				<li>
					<a href={config.social.github.url} title={`Follow ${config.social.github.username} on GitHub`} target="_blank">
						GitHub
					</a>
				</li>
				<li>
					<a href={config.social.linkedin.url} title={`Follow ${config.social.linkedin.username} on LinkedIn`} target="_blank">
						LinkedIn
					</a>
				</li>
				<li>
					<a href={config.social.instagram.url} title={`Follow ${config.social.instagram.username} on Instagram`} target="_blank">
						Instagram
					</a>
				</li>
				<li>
					<a href={config.social.goodreads.url} title={`Follow ${config.social.goodreads.username} on Goodreads`} target="_blank">
						Goodreads
					</a>
				</li>
				<li>
					<a href={config.social.letterboxd.url} title={`Follow ${config.social.letterboxd.username} on Letterboxd`} target="_blank">
						Letterboxd
					</a>
				</li>
			</ul>

			<h3>Colophon</h3>
			<p>This website is built using <a href="https://gatsbyjs.org">Gatsby</a>, and hosted on <a href="https://pages.cloudflare.com">CloudFlare Pages</a>. Text is set in New York & San Francisco, and code in San Francisco Mono. More about how I built it soon.</p>
		</div>
	</BaseLayout>
)

export default AboutPage
