import React from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import SocialCard from '../components/social-card'

import { FeaturedWork } from './work'

import ArrowIcon from '../assets/arrow.svg'

import config from '../../config/site-config'
import Head from '../components/head'
import Meta from '../components/meta'
import Header from '../components/header'
import Footer from '../components/footer'

const Hello = () => (
	<div className="homepage--hello">
		<div className="contents">
			<h1 className="name">
				Hi, I’m Harshil <span className="wave">👋🏽</span>
			</h1>
			
			<p>I’m an independent iOS engineer living in Mumbai, India.</p>
			
			<p>I make <a href="https://sneakpeak.app" title="Peak">Peak</a>, an iPhone, iPad, & Apple Watch app that lets you build your own fitness dashboard, and <a href="/pause" title="Pause">Pause</a>, a Mac app that reminds you to rest.</p>
			
			<p>I’m currently looking for contract work. If you’d like to speak, you can <a href={config.social.email.url} title={`Send an email to ${config.social.email.username}`}>send me an email</a>.</p>
		</div>
	</div>
)

const Work = () => (
	<div className="homepage--work">
		<div className="contents">
			<h2 className="section-title">Selected Work</h2>
			<FeaturedWork className="list"/>
			<div className="view-all-container">
				<Link to={config.work} title="View All Work" className="view-all">
					View All <ArrowIcon />
				</Link>
			</div>
		</div>
	</div>
)
	
const RecentPosts = ({data}) => (
	<div className="homepage--recent-posts">
		<div className="contents">
			<h2 className="section-title">Recent Posts</h2>
			<ol className="homepage--post-list list">
				{data.allMarkdownRemark.nodes.map(post => {
					const id = post.id
					const title = post.frontmatter.title
					const description = post.frontmatter.description
					const link = config.blogPrefix + post.frontmatter.slug
					const linkTitle = "Read ”" + title + "”"
					
					return(
						<li key={id} className="post-list--item">
							<Link to={link} title={linkTitle} className="post-link">
								<h1 className="title">{title}</h1>
								{ description && <p className="description">{description}</p> }
								<p className="excerpt">{post.excerpt}</p>
							</Link>
						</li>
					)
				})}
			</ol>
			{
				data.allMarkdownRemark.totalCount > blogPostLimit && 
				<div className="view-all-container">
					<Link to={config.blogPrefix} title="View All Posts" className="view-all">
						View All <ArrowIcon />
					</Link>
				</div>
			}
		</div>
	</div>	
)

const IndexPage = ({data}) => (
	<div className="main">
		<div className="home">
			<Meta />
			<Helmet>
				<html data-style="main" />
			</Helmet>
			<Head title={config.siteTitle} />
			<SocialCard title={config.siteTitle} />
			<Header className="header-wrapper header-home" />
			<Hello />
			<Work />
			<RecentPosts data={data} />
			<Footer className="footer-wrapper footer-home" />
		</div>
	</div>
)
				
export default IndexPage

const blogPostLimit = 3

export const blogQuery = graphql`
	query {
		allMarkdownRemark(
			filter: { fileAbsolutePath: { regex: "/blog\/" } }
			sort: { fields: [frontmatter___date], order: DESC }
			limit: 6
		) {
			totalCount
			nodes {
				id
				excerpt(pruneLength: 100)
				frontmatter {
					title
					description
					slug
				}
			}
		}
	}
`;
