import React from 'react'
import { Link } from 'gatsby'
import moment from 'moment'

function formattedTags(tags) {
  const tagLinks = tags.map(tag => {
    return(
      <Link to={`/blog/#` + tag.toLowerCase().split(' ').join('-')} key={tag} className="metadata-link">
        {tag}
      </Link>
    )
  })

  if (tagLinks.length === 0) {
    return
  } else if (tagLinks.length === 1) {
    return(<p className="metadata-item">Tagged with {tagLinks[0]}</p>)
  } else {
    return(
      <p className="metadata-item">
        Tagged with
        {tagLinks.map((item, index) => {
          if (index === tagLinks.length - 1) {
            return [` and `, item]
          } else {
            return [` `, item, `,`]
          }
        })}
      </p>
    )
  }
}

const PostMetadata = ({date, link, tags}) => {
  const formattedDate = moment(date).format("D MMMM, YYYY")

  return(
    <div className="metadata-wrapper">
      <p className="metadata-item">Posted on {formattedDate}</p>
      {/* <p className="metadata-item">Bookmark the <Link to={link} className="metadata-link">permalink</Link></p> */}
      {/* {tags && formattedTags(tags)} */}
    </div>
  )
}

export default PostMetadata